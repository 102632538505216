.i-earn-gold-container {
    width: 100%; height: 100%;
    position: relative;
}

.i-earn-gold-over {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%; height: 100%;

}

.i-earn-gold-over h3 {
    padding: 0; margin: 0;
    width: 50vw;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 3vw;
    color: white;
    transform:translate(calc(3vw + 60px), calc(3vw + 60px));
}

.i-earn-gold-container img{
    width: 100%;
}