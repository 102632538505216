.header {
    height: calc(600px + var(--ryon-nav-height)); 
    width: 100%;
    text-align: left;
    position: relative;
}

.header-bg{
    width: 100%; height: 100%;
    position: absolute;
    background: #232882;

}


#header-animation {
    width: 100%;
    height: 100%;
}


.loop-animation {
    height: 100%;
    left: 40vw;
    position: absolute;
}


.quote {
    display: inline-block;
    left: 0; right: 0;
    margin:0 var(--ryon-side-space);
    height: 100%;
    padding-top: calc(120px + var(--ryon-nav-height));
    color: white;
    position: absolute;
    z-index: 1;
    text-align: left;

    font-size: 48px;
    line-height: 48px;
}

.quote span{
    font-weight: 500;

    font-family: 'Poppins';
    color: #ffffff;

    filter: drop-shadow(0 0 15px rgba(255,255,255,0.3));
}

.quotes-enter-active {
    animation: quotes-entering 1000ms ease-in-out forwards;
}

.header-desc {
    margin: 30px 0;
    width: 50%;
    font-weight: 200;
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 24px;
}

.header-button {
    position: relative;
    display: block;
    background-color: rgba(255,255,255, 0.0);
    color:white;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    height: 50px;
    line-height: 47px;
    border-radius: 50px;
    width: 200px;
    margin: 30px 0;
    cursor: pointer;
    border: 3px solid white;
        overflow: hidden;
    box-shadow: 0 0 10px rgba(255,255,255,0.7) inset, 0 0 10px rgba(255,255,255,0.7);
    text-shadow: 0 0 5px rgba(255,255,255,0.5);

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.header-button:hover {
    color:#232882;
    box-shadow: 0 0 5px rgba(255,255,255,0.7) inset, 0 0 5px rgba(255,255,255,0.7);

    text-shadow: none;
}

.header-button svg {
    height: 20px; width: 20px;
    transition: fill 1s ease;
    filter: drop-shadow(0 0 5px rgba(255,255,255,0.5));
    transform: translate(5px, 2px);
    -ms-transform: translate(5px, 2px);
    -webkit-transform: translate(5px, 2px);
    -o-transform: translate(5px, 2px);
    -moz-transform: translate(5px, 2px);
}

.header-button:hover svg {
    fill: #232882;
}

.header-button:hover:after {
    height: 450%;
}

.header-button:after {
    content: "";
    position: absolute;
    height: 0%;
    left: 50%;
    top: 50%;
    width: 150%;
    z-index: -1;

    background: white;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
    transform: translateX(-50%) translateY(-50%) rotate(-25deg);

    -webkit-transition: all 750ms ease 0s;
    -moz-transition: all 750ms ease 0s;
    -o-transition: all 750ms ease 0s;
    transition: all 750ms ease 0s;
}

.header-button-anim-enter-active {
    animation: header-button-entering 1500ms ease-in-out forwards;
}

/*
.header-button:hover, .header-button:focus {
color:#232882;
box-shadow: inset 0 0 0 2em white;
}
*/

@media only screen and (max-width: 768px){
    .header {
        background-color: #232882;
    }
    .header::before {
        background-image: none;
    }
    .quote {
        font-size: 36px;
        line-height: 40px;
    }

    .header-desc {
        font-size: 14px;
    }
}


@media only screen and (max-width: 512px){
    .header-desc {
        width: 80%;
        font-size: 12px;
        line-height: 20px;
    }
    .quote {
        font-size: 24px;
        line-height: 28px;
    }
}