.emp-sect-container {
    margin: 0; padding: 0;
}

.emp-sect-container h4 {
    text-align: left;
}

.emp-sect-grid {
    display: grid;
    grid-template-areas: 
    "emp-teammate emp-details"
    "emp-advisor emp-details"
    "emp-branch emp-details";
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: (minmax(100px, auto));

    row-gap: 20px;
    column-gap: 50px;
}

.emp-teammate { grid-area: emp-teammate; }
.emp-advisor { grid-area: emp-advisor; }
.emp-branch { grid-area: emp-branch; }
.emp-details {  grid-area: emp-details; }

.emp-details {
    background-color: white;
    padding: 30px;
    border-radius: var(--ryon-box-border);
    text-align: left;
    position: relative;
}

.emp-details-container {
    position: sticky;
    top: 80px;
}


.emp-details h3 {
    margin-top: 0;
    padding: 0;
    color: #232882;
}

.emp-details-overlay-wrapper {
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0; right: 0; bottom: 0;
    border-radius: var(--ryon-box-border);
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
}

.emp-details-overlay {
    width: 100%; height: 100%;
    display: flex;
    background-color: #fff;
    pointer-events: auto;

    transform: translateX(0%);
    transition: transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out;
    -moz-transition: transform .3s ease-in-out;
}

.emp-details-overlay-display {
    transform: translateX(101%);
}


.emp-details-overlay p {
    height: 100%; width: auto;
    padding: 20px;
    padding-top: 30px;
    color: #6b6b6b;
    font-size: 18px;
    font-family: 'Poppins';
}

.emp-details-overlay-header{
    color: #232882;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -2px;
}

.emp-details-aff-container {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.emp-details-aff-container span {
    margin-right: 10px;
}

.emp-details-aff {
    font-weight: 600;
    color: #4d4d4d;
    font-size: 16px;
    white-space: nowrap;
}

.emp-details-aff-pt {
    flex: 1;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
}

.emp-details-aff-pos {
    color: #707070;
    font-size: 14px;
    white-space: nowrap;
}

.emp-details-aff-term {
    color: #4d4d4d;
    font-size: 12px;
    white-space: nowrap;
    margin-left: auto;
}

.emp-container {
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.emp-wrapper {
    list-style: none;
    text-align: center;
    
    width: 250px;
    margin: 20px 0;
    

    cursor:pointer;
}

.emp-picture{
    width: 200px; height: 200px;
    border-radius: 50%;

    transition: filter .2s ease-in-out;
    -o-transition: filter .2s ease-in-out;
    -moz-transition: filter .2s ease-in-out;
}

.emp-text {
    height: 0;
    opacity: 0;
    position: relative;
    top: -60%;
    color:#fff;

    transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
    .emp-wrapper:hover .emp-text {
        opacity: 1;
    }

    .emp-wrapper:hover .emp-picture {
        filter:brightness(0.4);
    }
}

.emp-name {
    font-weight: 700;
    font-size: 18px;
}

.emp-position {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
}

.emp-wrapper-details {
    display: none;
}

@media only screen and (max-width: 1024px){
    .emp-sect-grid {
        grid-template-areas: 
        "emp-teammate"
        "emp-advisor"
        "emp-branch";
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .emp-details {
        display: none;
    }

    .emp-wrapper {
        background-color: white;
        border-radius: var(--ryon-box-border);
        width: calc(50% - 20px);
        text-align: left;
        padding: 20px;
    }

    .emp-text {
        padding: 10px 30px;
        display: inline-block;
        position: static;
        height: auto;
        color: inherit;
        opacity: 1;
        vertical-align: top;
    }

    .emp-wrapper:hover .emp-picture {
        filter: none;
    }

    .emp-picture {
        width: 100px; height: 100px;
        
    }
    
    .emp-wrapper-details {
        display: block;
        max-height: 0;
        overflow: hidden;

        transition: max-height .3s ease-in-out;

    }

    .emp-wrapper-details-show {
        max-height: 300px;
    }

}

@media only screen and (max-width: 768px){
    .emp-wrapper {
        width: 100%;
    }


}

@media only screen and (max-width: 370px){
    .emp-text {
        display: block;
    }
}