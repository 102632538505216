.nav {
  z-index: 2;
  position: fixed;
  width: 100%;
  height: var(--ryon-nav-height);
  background-color: rgba(35,40,130, 1);
  top: 0;
    
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 10;
  
}

.nav-blue {
  background-color: rgba(35,40,130, 1);
}

.nav-white {
  background-color: white;
}

.nav ul {
  height: var(--ryon-nav-height);
  float: right;
  list-style: none;
  display: inline-flex;
  margin-right: var(--ryon-side-space);
  white-space: nowrap;
}

.nav ul:after {
  clear:both;
}

.nav-button {
  height: var(--ryon-nav-height);
  width: auto;
  padding: 0 3vw;
  position: relative;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.nav-button span {
  padding: 0;
  line-height: calc(var(--ryon-nav-height) - var(--ryon-nav-button-bar-height));
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  transition: .3s color;
  -webkit-transition: .3s color;
  -moz-transition: .3s color;
  -o-transition: .3s color;
}

.nav-blue .nav-button span {
  color: white;
}

.nav-white .nav-button span {
  color:#232882;
}

.nav-button:hover .nav-button-bar {
  width: 100px;
}

.nav-blue nav-button:hover span {
  color:#c7cceb;
}

.nav-white nav-button:hover span {
  color:#6270c4;
}

.nav-button-bar { 
  margin: 0 auto;
  height: var(--ryon-nav-button-bar-height);
  width: 0%;
  background-color: white;
  position: absolute;
  transform:translateX(-50%); left: 50%;
  max-width: calc(100%);

  transition: .3s width;
  -webkit-transition: .3s width;
  -moz-transition: .3s width;
  -o-transition: .3s width;
}

.nav-blue .nav-button-bar {
  background-color: white;
}

.nav-white .nav-button-bar {
  background-color:#232882;
}

.nav-logo {
  margin-left: var(--ryon-side-space);
  height: var(--ryon-nav-height);
  width: 300px;
  display: inline-block;
  list-style: none;
  cursor: pointer;
  
}

.nav-logo img{
  height: calc(var(--ryon-nav-height) - 30px);
  margin: 15px 0;
  cursor: pointer;
  position: absolute;
  transition: filter .3s;  
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.nav-white img {
  filter: brightness(1);
  -webkit-filter: brightness(1);
}

.nav-menu-button {
  display:none;
  position: absolute;
  right: 0;
  transform: translate(-30px, 20px);

  cursor: pointer;

  transition: fill 1s ease;
  -webkit-transition: fill 1s ease;
  -moz-transition: fill 1s ease;
  -o-transition: fill 1s ease;
}

.nav-menu-button svg {
  height: 20px; width: 20px;
  fill: white;
}

.nav-white .nav-menu-button svg {
  fill:rgba(35,40,130, 1);
}

/* -------------------------- MOBILE -----------------------------*/

.side-menu {
  z-index: 2;
  display: none;
  position: fixed;
  background-color: white;
  height: 100%;
  width: 230px;
  padding-top: calc(var(--ryon-nav-height) + 10px);
  right: -100%;
  backdrop-filter: blur(8px);
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
}

.side-menu.side-menu-show {
  right: 0;
}

.side-menu a {
  text-decoration: none;
}

.side-menu span {
  display: block;
  padding: 10px 20px;
  font-weight: 700;
  color:#232882;
  cursor: pointer;

  transition: all .1s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
}

.side-menu span:hover{
  color:#6270c4;
  padding-left: 30px;
}

.side-menu-backdrop {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  position: fixed;
  width: 100%; height: 100%;
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(10px);
  content: none;

  transition: visibility 0s, opacity .3s ease-out;
  -webkit-transition: visibility 0s, opacity .3s ease-out;
}

.side-menu-backdrop-show {
  visibility: visible;
  opacity: 1;
}


@media only screen and (max-width: 1366px){
  .nav-button {
    padding: 0 2.5vw;
  }

  .nav-button:hover .nav-button-bar {
    width: 80px;
  }
}

@media only screen and (max-width: 1024px){
  .nav-button {
    padding: 0 2.2vw;
  }

  .nav-button:hover .nav-button-bar {
    width: 60px;
  }

  .nav-button span {
    font-size: 12px;
  }

}

@media only screen and (max-width: 768px) {
  .nav-button {
    display: none;
  }

  .nav-menu-button {
    display: inline-block;
  }

  .nav-logo img {
    height: calc(var(--ryon-nav-height) - 35px);
    margin-top: 17.5px;
  }

  .side-menu {
    display: block;
  }
}
