.footer {
    padding-top: 30px;
    background-color: #e9ebf7;
    width: 100%;
    transition: 1s ease-out;
}

.footer-container {
    margin: 0 var(--ryon-side-space);
    height: 200px;
    
}

.footer-wrapper {
    display: inline-block;
    width: 20%; height: 100%;
    vertical-align: top;
}

.footer-wrapper:nth-child(1) {
    width: 40%;
}

.footer-wrapper-title {
    margin: 5px 5px; padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
}

.footer-wrapper-links {
    padding: 0px 5px;
    list-style: none;
    font-size: 14px;
}

.footer-wrapper-links * {
    text-decoration: none;
}

.footer-wrapper-links li {
    color:#5e5e5e;
    cursor: pointer;

    padding: 5px 0;
    transition: color .1s;
}

.footer-wrapper-links li:hover {
    color: #2b2b2b;
}

.copyright {
    padding-bottom: 10px;
    width: 100%;
    color: #9d9d9d;
    font-size: 12px;
    text-align: center;
    position: relative;
}

.footer h1 {
    margin: 10px 5px; padding: 0;

    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 26px;
}

@media only screen and (max-width: 768px){
    .footer-wrapper {
        display: block;
        margin-bottom: 30px;
        width: 40%;
    }

    .footer-wrapper:nth-child(1) {
        width: 100%;
    }

    .footer-container {
        height: auto;
    }
}