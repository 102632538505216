.blog-list-container {
    list-style: none;
}

.blog-list-wrapper {
    border-radius: var(--ryon-box-border);
    background-color: white;
    margin: 20px 0;
    overflow: hidden;
}

.blog-box {
    display: block;
    text-decoration: none !important;
    
}

.blog-box-preview {
    width: 100%;
}

.blog-box-text {
    padding: 20px;
}

.blog-box h4{
    color: black;
    font-size: 24px;
}

.blog-box h4 i { margin-left: 10px; }

.blog-box-desc { color: #9d9d9d; }

.blog-box-info { 
    margin-top: 20px; 
}

.blog-box-info * {
    font-weight: 600;
    display: inline-block;
    margin-right: 20px;
}

.blog-box-author {
    color: #232882;
}

.blog-box-url {
    margin: 0;
    color: #9d9d9d;
    float: right;
}

.blog-box-date {
    color: #525252;
}

.no-preview {
    display: none;
}