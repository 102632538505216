.contact-us-title {
    margin: 10px 0;
    display: block;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
    color: #4756ba;
}

.contact-us-input {
    border: none;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    resize: none;    
}

.contact-us-send {
    display: block;
    padding: 15px;
    border-radius: var(--ryon-box-border);
    border: none;
    color: white;
    margin: 30px 0;
    width: 200px; height: 60px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    
	background: linear-gradient(-45deg, #232882, #4756ba, #232882);
	background-size: 400% 400%;
	animation: gradient 6s ease-in-out infinite;

    transition: font-size .2s ease-out;
}

@media (hover: hover) and (pointer: fine) {
    .contact-us-send:hover{
        font-size: 20px;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
