@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Open Sans", "Arial", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-variation-settings:
  "wdth" 100;
}

html, body {
  height: 100%; width: 100%;
}

.app {
  --ryon-nav-height: 60px;
  --ryon-nav-button-bar-height: 2px;
  --ryon-side-space: calc(33vw - 300px);
  --ryon-box-border: 10px;

  background-color: #f4f5fa;
}

.main-content {
  position: relative;
	width: 100%;
	height: 100%;
	perspective: 1200px;
	transform-style: preserve-3d;
}

main {
  height: auto;
  width: 100%;
  position: relative;
  background-color: #f8f9fc;
  padding-top: 60px;
}

.main-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	visibility: visible;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
}

h3 {
  margin: 10px 0;
  width: 100%;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 28px;
  text-align: left;
  color: #000;
  padding: 0 var(--ryon-side-space);
}

h4 {
  display: inline-block;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  color: #525252;
}

h5 {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #5e5e5e;
}

.sect-container {
  height: auto;
  padding-bottom: 70px;
  align-items: stretch;
  justify-content: center;
  
}

.sect-desc{
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: #434343;
}

.sect-box-wrapper {
  display: flex;
  justify-content: space-between;
	flex-wrap: wrap;
}

.sect-box {
  margin: 10px 0; padding: 20px 40px;
  background-color: white;
  border-radius: var(--ryon-box-border);
  height: auto;
}

.sect-box-link {
  display: block;
  text-decoration: none;
  color: #434343;

  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.sect-box h4{
  color:#2e2e2e;
  transition: color .2s ease-in-out;

}

.sect-box-full {
  width: 100%;
}

.sect-box-half {
  width: calc(50% - 10px);
}

.sect-container hr {
  margin: 10px 0;
  height: 1px;
  border: none;
  background-color: #9d9d9d;
}

.sect-side-space {
  padding: 0 var(--ryon-side-space);
}

.sect-box-sto {
  text-align: center;
  margin: 20px auto;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #232882;
  background-color: white;
  padding: 20px 30px;
  border-radius: var(--ryon-box-border);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.sect-box-sto span {
  font-weight: 700;
  font-size: 20px;
}

.sect-link {
  text-decoration: none;
  color: #000;

  transition: background-color .1s ease-in-out, color .1s ease-in-out;
}



@media (hover: hover) and (pointer: fine) {
  .sect-box-sto:hover{
    cursor: pointer;
    color: white;
    background-color: #232882;
  }

  .sect-link:hover{
    color: white;
    background-color: #232882;
  }
}

.sect-img {
  display: block;
  margin: 30px 0 ;
  width: 100%;
  height: auto;
}

.sect-img-back {
  position: absolute;
}

.community {
  padding: 10px 0;
  background-color:#232882;
  width: 100%;
  height: 200px;
}

.community p {
  margin: 15px 0;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 700;
}

.community-list {
  display: table;
  margin: 0 auto;
  list-style: none;
}

.community-button {
  margin: 25px 30px;
  width: 60px; height: 60px;
  border-radius: 40px;
  background-color:#414eb0;
  display: inline-flex;
  
  cursor: pointer;

  transition: background-color .2s;
  -webkit-transition: background-color .2s;
}

.community-button a {
  position: absolute;
  width: 60px; height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.community-button:hover {
  background-color: #6270c4;
}

.community-button:hover img{
  transform: scale(1.1);
}

.community-button img{
  margin: 0 auto;
  width: 60%;
  
  transition: transform .2s;
}

.partner {
  background-color: #2e2e2e;
  padding: 20px 0;
  width: 100%; height: auto;
  
  text-align: center;
}

.partner-image-helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.partner-image {
  margin: 0 30px;
  height: 50px;
  vertical-align: middle;
  opacity: .2;

  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
}

#partner-ista-logo {
  height: 100px;
}

@media (hover: hover) and (pointer: fine) {
  .partner-image:hover {
    transform: scale(1.1);
    opacity: .5;
  }
}

@media (hover: hover) and (pointer: fine) {
  #ssga-link:hover {
    background-color: #e6ff00;
  }

  #invesco-link:hover {
    color: white;
    background-color: #000ad2;
  }

  #invesco-link:hover h4 {
    color: white;
  }
}

/* ------------------ MOBILE ------------------- */

@media only screen and (max-width: 1024px){
  .app {
    --ryon-side-space: 30px;
  }
}

@media only screen and (max-width: 768px){

  .sect-box-half {
    width: 100%;
  }

  .community-button {
    margin: 0 10px;
    width: 50px; height: 50px;
    border-radius: 40px;
    background-color:#414eb0;
    display: inline-flex;
    
    cursor: pointer;
  
    transition: background-color .1s;
  }

  .community-button a {
    width: 50px; height: 50px;
  }
}



/* --------------- Animation ------------------ */

@keyframes quotes-entering{
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes quotes-entering{
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes header-button-entering {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes header-button-entering {
  0% { opacity: 0; }
  100% { opacity: 1; }
}